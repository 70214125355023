import React from 'react';

import Layout from '../components/Layout';
import SEO from '../components/SEO';

import styles from '../components/Button/button.module.scss';

const Styleguide = () => (
  <Layout>
    <SEO title="Styleguide" />

    <div className={styles.container}>
      <p>
        <button type="button">
          Button
        </button>
      </p>
      <p>
        <button type="button" className={styles.primary}>
          Primary
        </button>
      </p>
      <p>
        <button type="button" className={styles.secondary}>
          Secondary
        </button>
      </p>
      <p>
        <button type="button" className={styles.disabled}>
          Disabled
        </button>
      </p>
    </div>
  </Layout>
);

export default Styleguide;
